/*globals document, $ */

(function () {
    "use strict";

    var searchFormats = function (term) {
        if (term === '') {
            // Show everything
            $('.format').show();
            $('.targets').hide();
            $('.targets .target').show();

            $('.format h3').data('expanded', false).find('i').removeClass('fa-angle-down').addClass('fa-angle-right');
        } else {
            // We'll hide everything, then just show those that match
            $('.format, .target').hide();

            $('.target').each(function () {
                var name, description;
                
                name = $(this).find('.target-ext').text().trim().toLowerCase();
                description = $(this).find('.description').text().trim().toLowerCase();

                // Does it match?
                if (name.indexOf(term.toLowerCase()) !== -1 || description.indexOf(term.toLowerCase()) !== -1) {
                    $(this).show().parents('.targets').show().parents('.format').show();
                    $(this).parents('.format').find('h3').data('expanded', true).find('i').removeClass('fa-angle-right').addClass('fa-angle-down');
                }
            });

            // Now search for the format in the source format and show all children
            $('.format h3').each(function () {
                var name = $(this).find('> strong').text().trim().toLowerCase(),
                    description = $(this).find('> span').text().trim().toLowerCase();

                if (name.indexOf(term.toLowerCase()) !== -1 || description.indexOf(term.toLowerCase()) !== -1) {
                    // Expand the list
                    $(this).data('expanded', true).find('i').removeClass('fa-angle-right').addClass('fa-angle-down');
                    $(this).parents('.format').show().find('.targets').show().find('.target').show();
                }
            });
        }
    };
    
    var scrollFirstBlock = function () {
        // Scroll to the first visible format
        var offset = $('.formats .format:visible').first().parents('.formats').offset();
        if (offset) {
            $('html, body').animate({
                scrollTop: offset.top
            }, 1000);
        }
    }

    $(document).ready(function () {
        // Set up the expand and collapse for the formats
        $('.format h3').click(function () {
            var expanded = $(this).data('expanded') === true;

            console.log('Expanded: ' + expanded);
            if (expanded) {
                $(this).find('i').removeClass('fa-angle-down').addClass('fa-angle-right');
                $(this).parent().find('.targets').slideUp(400);
            } else {
                $(this).find('i').removeClass('fa-angle-right').addClass('fa-angle-down');
                $(this).parent().find('.targets').slideDown(400);
            }

            $(this).data('expanded', !expanded);
        });

        $('#search-formats').keyup(function () {
            searchFormats($(this).val());
        });

        $('#search-formats').change(function () {
            searchFormats($(this).val());
        });
        
        $('#search-formats').keypress(function (e) {
            if (e.which == 13) {
                scrollFirstBlock();
            }
        });
        
        $('#search-formats').blur(function (e) {
            scrollFirstBlock();
        });
        
        if ($('.type-nav').length > 0) {
            $('body').scrollspy({target: '.type-nav'});
        }
    });
}());

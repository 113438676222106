import braintreeClient from "braintree-web/client";
import threeDSecure from "braintree-web/three-d-secure";

document.addEventListener('DOMContentLoaded', async () => {
    // Return early if the current page isn't the signup page
    if (document.body.id !== "signup") return;

    const paymentNonce = document.body.dataset.paymentNonce;

    // Return early if there's no payment nonce (i.e., no further verification needed)
    if (paymentNonce === "") return;

    const braintreeClientToken = document.body.dataset.clientToken;
    const amount = document.body.dataset.subscriptionAmount;
    const cardBIN = document.body.dataset.cardBin;

    const result = await startThreeDSecureFlow(braintreeClientToken, paymentNonce, amount, cardBIN);

    if (result) {
        document.querySelector('#card_nonce').value = result;
        document.querySelector('#' + document.body.id + '-form-details').submit();
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const submitButtons = document.querySelectorAll('form[data-3ds-enabled] button[type=submit]');

    // Intercept form submissions and instead perform a 3DS flow
    submitButtons.forEach(function (submitButton) {
        submitButton.addEventListener("click", async function (event) {
            const form = event.target.form;
            const fields = form.elements;
            const clientToken = fields.clientToken.value;

            // See: https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side/javascript/v3/#recommended-settings
            const threeDSecureOptions = {
                // We have to include these:
                amount: fields.amount.value,
                nonce: fields.nonce.value,
                bin: fields.bin.value,

                // Since August 2024, Visa requires these as well:
                collectDeviceData: true,
                email: fields.cardHolderEmail.value,
                billingAddress: {
                    givenName: fields.cardHolderGivenName.value,
                    surname: fields.cardHolderSurname.value,
                },
                additionalInformation: {
                    ipAddress: fields.ipAddress.value,
                }
            };

            if (fields.nonce.value) {
                event.preventDefault();
                const result = await startThreeDSecureFlow(clientToken, threeDSecureOptions)

                if (result) {
                    fields.nonce.value = result;
                    form.submit();
                }
            }
        });
    });
});

async function startThreeDSecureFlow(authorization, options) {
    try {
        // Return early if there's no payment nonce (i.e., no further verification needed)
        console.log("Starting 3DS flow");

        // Loading 3DS resources can feel "laggy" so display "processing" text and spinner
        toggleOverlay("block");

        // Use the generated client token to instantiate the Braintree client.
        console.log("Creating Braintree client");
        const client = await braintreeClient.create({authorization});

        console.log("Creating threeDSecure client");
        const instance = await threeDSecure.create({client, version: 2});
        console.log("Created threeDSecure client", instance);

        const [status, cardNonce] = await runThreeDSecureFlow(instance, options);
        console.log(`3DS status: ${status}`);
        return cardNonce ? cardNonce : status;

    } catch (err) {
        console.log("Start 3DS ERROR!");
        console.log(err);
        toggleOverlay("none"); // Remove "processing" overlay
        return null;
    }
}

async function runThreeDSecureFlow(instance, options) {
    try {
        console.log("Validating 3DS nonce", instance, options);

        options.onLookupComplete = (data, next) => next();

        const response = await instance.verifyCard(options);

        console.log("Verifying response");
        console.log(response);

        if (response && response.liabilityShifted) {
            return ["3ds-success", response.nonce];

        } else if (response && response.liabilityShiftPossible) {
            return ["3ds-failed"];

        } else {
            return ["3ds-not-supported"];
        }

    } catch (err) {
        console.log("3DS ERROR!");
        console.log(err);
        return ["3ds-error"];
    }
}

function toggleOverlay(state) {
    if (document.getElementById("overlay-processing")) {
        document.getElementById("overlay-processing").style.display = state;
    }
}

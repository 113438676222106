import $ from "jquery";

/**
 * Many browsers display a vague message (such as "Match the format requested") when pattern
 * validation fails. Some browsers use the title field to display a more descriptive error, but
 * support for this is not universal.
 *
 * To customise the message that is displayed when an HTML pattern validation fails, add a
 * data-pattern-instructions attribute on any form input that contains a data-pattern attribute.
 *
 * For example:
 *   <input
 *     type="text"
 *     name="medal"
 *     pattern="gold|silver|bronze"
 *     data-pattern-instructions="Enter gold, silver or bronze"
 *   />
 */
$(() => $("[data-pattern-instructions]").on("input", function () {
    this.setCustomValidity(this.validity.patternMismatch ? this.dataset.patternInstructions : "");
}));
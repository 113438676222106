/*global $, document, window */

(function () {
    "use strict";

    // Take the plans table and build a responsive list view of it
    $(document).ready(function () {
        var plansTable = $('#plans'),
            planTitles = $('thead > tr > td:not(:first)', plansTable),
            responsivePlans = $('<div id="plans-responsive"></div>');

        planTitles.each(function (planIndex, planTitle) {
            var plan, colour, popular, expand, signup, href, html;

            planTitle = $(planTitle);
            colour = 'black';
            if (planTitle.hasClass('blue')) {
                colour = 'blue';
            } else if (planTitle.hasClass('green')) {
                colour = 'green';
            } else if (planTitle.hasClass('yellow')) {
                colour = 'yellow';
            }
            popular = planTitle.hasClass('popular');

            plan = $('<div class="plan"></div>').addClass(colour);
            if (popular) {
                plan.addClass('popular');
            }

            // Add the heading
            plan.append($('<div class="heading"></div>').html(planTitle.html()));

            // Get all the features
            $('tbody tr td:first-child', plansTable).each(function (featureIndex, featureTD) {
                var label, feature, row;

                row = $(featureTD).parent();

                if (!row.hasClass('last-row')) {

                    label = $('<label></label>').html($(featureTD).html());
                    feature = $('<div class="feature"></div>');
                    if (featureIndex >= 3) {
                        feature.hide();
                    }

                    feature.append(label);

                    feature.append($('<div>' + $('td:nth(' + (planIndex + 1) + ')', row).html() + '</div>'));

                    plan.append(feature);
                }
            });

            // Add the expand button
            expand = $('<div class="expand"><a href="#" title="Show more"></a></div>');
            plan.append(expand);

            // Add the signup button
            html = $('table#plans tbody tr.last-row td:nth(' + (planIndex + 1) + ')').html();
            signup = $('<div class="signup"></div>').html(html);
            plan.append(signup);

            // Bind to the expand
            $('a', expand).click(function () {
                $('.feature', $(this).parents('.plan')).show();
                $(this).parents('.expand').hide();
                return false;
            });

            responsivePlans.append(plan);
        });

        plansTable.after(responsivePlans);
    });
}());

import "bootstrap";
import bootbox from "bootbox";

/**
 * Button Processing - This utility binds to buttons that want to be disabled when processing and show a spinning
 * icon to show the user that activity is happening
 */
$(document).ready(function () {
    var showProcessing = function(el) {
      $(el).attr('disabled', 'disabled');
      $(el).text(' ' + $(el).attr('data-processing'));
      $(el).prepend($('<i class="fa fa-circle-o-notch fa-spin"></i>'));
    };

    $('.submit-confirm').click(function () {
        var t = this;
        bootbox.confirm($(this).attr('data-confirm'), function (confirm) {
            if (confirm) {
                $(t).parents('form').submit();
            }
        });
        return false;
    });

    $('.submit-prompt').click(function () {
        var t = this;
        bootbox.prompt($(this).attr('data-prompt'), function (value) {
            if (value) {
                // Show any processing status, if this element defines one
                if ($(t).attr('data-processing')) {
                  showProcessing(t);
                }

                // Update the form's field with the user's value and then submit the form
                var form = $(t).parents('form');
                var fieldName = $(t).attr('data-prompt-for-field');
                var field = form.children("[name='" + fieldName + "']")
                field.val(value);
                form.submit();
            }
        });

        // Set the placeholder of the input field
        if ($(this).attr('data-prompt-placeholder')) {
          $(".bootbox-input")[0].placeholder = $(this).attr('data-prompt-placeholder');
        }

        // Add an explainer underneath the input field
        if ($(this).attr('data-prompt-label')) {
          var label = document.createElement("label");
          label.textContent = $(this).attr('data-prompt-label');
          $(".bootbox-form")[0].appendChild(label);
        }

        return false;
    });

    $('.submit-reason').click(function () {
        var t = this;

        var form = $(this).parents('form');

        bootbox.dialog({
            message: 'We\'re sorry that you want to cancel your account.  We would appreciate it if you could spare a few moments to tell us how we can improve<br><br><textarea id="cancel-feedback" class="form-control" placeholder="How could we improve ?" style="display: block; width: 100%; box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box;"></textarea>',
            title: 'Cancel account ?',
            buttons: {
                success: {
                    label: 'Yes, cancel my account',
                    className: 'btn-danger',
                    callback: function () {
                        var reason = $('#cancel-feedback').val();
                        form.find('input[name="reason"]').val(reason);
                        form.submit();
                    }
                },
                main: {
                    label: 'Keep my account',
                    className: 'btn-success',
                    callback: function () {
                        // Don't need to do anything, as it'll auto close
                    }
                }
            }
        });

        return false;
    });
});

import $ from "jquery";

/**
 * To ensure that a password confirmation input has the same value as a password input, add a
 * data-password-confirmation-for attribute on any form input.
 *
 * For example:
 *   <input id="newPassword" type="password" required />
 *   <input type="password" data-password-confirmation-for="newPassword" required />
 */
$(() => $("[data-password-confirmation-for]").on("input", function () {
    const idOfPasswordField = this.dataset.passwordConfirmationFor;
    const password = document.getElementById(idOfPasswordField).value;
    const confirmation = this.value;

    const passwordsDoNotMatch = password.length > 0 &&
        confirmation.length > 0 &&
        confirmation !== password;

    this.setCustomValidity(passwordsDoNotMatch ? "Passwords must match" : "");
}));
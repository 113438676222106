/*
 * This file defines Zamzar's extensions and tweaks to the rails-ujs package.
 *
 * rails-ujs provides a few very useful HTML extensions, and installs them in an unobtrusive manner
 * (i.e., via HTML data- attributes rather than by requiring us to write lots of custom JavaScript).
 *
 * Despite its name, rails-ujs does not require Ruby on Rails.
 *
 * From rails-ujs, we make use of:
 *   data-remote to submit forms over AJAX
 *   data-confirm to display confirmation dialogues before performing destructive actions
 *   data-disable-with to prevent accidental double clicks of buttons
 *
 * See http://guides.rubyonrails.org/working_with_javascript_in_rails.html
 *
 * We aim to avoid customising rails-ujs if possible, because customisations make it
 * harder to upgrade our rails-ujs version and harder to on-board new developers.
 */
import Rails from 'rails-ujs'; // NB rails-ujs supersedes jquery-ujs (as of Rails 5.1)

// Add our own unobtrusive HTML extensions for validating forms
import "./validation/passwordConfirmationFor"
import "./validation/patternInstructions"

// Now that our extensions are installed, boot up Rails UJS
Rails.start();
/*global $, document, window */

(function () {
    "use strict";

    // Take the page navigation and convert it to a dropdown, but use media queries to show the correct one
    $(document).ready(function () {
        var select = $('<select class="nav-responsive"></select>');
        select.append('<option>Home</option>');
        if ($('#header a.logo').hasClass('selected')) {
            $('option', select).prop('selected', 'selected');
        }
        $('#navigation a').each(function (index, navItem) {
            var $nav = $(navItem);
            var isLinkToAnotherPage = !navItem.href.match(/#$/);

            if (isLinkToAnotherPage) {
              var item = $('<option></option>').val($nav.attr('href')).text($nav.text());
              if ($nav.hasClass('selected')) {
                  item.prop('selected', 'selected');
              }
              select.append(item);
            }
        });
        select.change(function () {
            window.location.href = $(this).val();
        });
        $('#navigation').append(select);
    });

}());
